@use 'common'
@use 'sass:math'

.Wrapper
	width: fit-content
	margin: auto
	background-color: #fff
	padding: 1rem 1rem 2rem
	border-radius: 1rem
	position: relative
	@media (min-width: common.$break48)
		padding: 5rem 5rem 2.5rem

.CloseBtn
	display: flex
	align-items: center
	border: none
	background-color: transparent
	color: common.$red
	font-family: inherit
	padding: 0
	font-size: 2.5rem
	margin-left: auto
	@media (min-width: common.$break48)
		position: absolute
		right: 10px
		top: 10px

.FormTitleWrapper
	display: flex
	flex-direction: column
	align-items: center
	margin-top: 2rem
	@media (min-width: common.$break48)
		margin-top: 0

.Title
	+common.subtitle
	margin: 0 0 0.5rem
	text-align: center

.Subtitle
	+common.text
	text-align: center
