@use 'common'
@use 'sass:math'

.Wrapper
	//

.Deactivated
	position: relative
	&::after
		position: absolute
		display: block
		content: ''
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: rgba(0, 0, 0, 0.5)
		z-index: 0

.DeactivatedModal
	display: block
	position: absolute
	inset: -8px 0 0 0
	width: 90vw
	height: fit-content
	background-color: #fff
	z-index: 1
	top: 6rem
	padding: 3rem 1rem 2rem 1rem
	color: #000
	margin: 0 auto
	border-radius: 1rem
	@media (min-width: common.$break48)
		top: 6rem
		padding: 4rem 2rem 2rem 2rem
		width: 50vw

.BackButton
	border-radius: 1.5rem
	padding: 0.3rem 0.75rem 0.3rem 0.5rem
	margin: 2rem 0 1rem
	font-size: 12px
	line-height: 18px
	@media (min-width: common.$break48)
		margin: 3rem 0 1rem

.ButtonArrow
	font-size: 24px
	transform: rotate(180deg)
	margin-right: 0.3rem

.ButtonWrapper
	text-align: center

.DeactivatedText
	text-align: center
	font-size: 1.25rem
	line-height: 1.25

.LogoSmall
	display: block
	font-size: 3rem
	color: common.$red
	margin-top: 1rem
	width: fit-content
	margin-inline: auto
