@use 'common'
@use 'sass:math'

.Wrapper
	position: sticky
	top: -1.25rem
	color: #fff

.Image
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0

.HeaderContent
	position: relative
	display: flex
	height: 100vh
	align-items: flex-end
	background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)

.HeaderContentIn
	display: flex
	flex-direction: column
	padding-bottom: 2rem

.Name
	color: inherit
	margin-bottom: 1rem
	max-width: 47rem

.KeyInfoBox
	border-top: 1px solid
	margin-top: 1rem

.KeyInfoWrapper
	display: flex
	padding: 9px

.KeyIcon
	margin-right: 11px
	display: flex
	align-items: flex-start
	min-width: 1rem
	padding-top: 3px
	filter: invert(1)

.KeyInfo
	margin: 0
