@use 'common'
@use 'sass:math'

.Banner
	display: flex
	flex-direction: column
	align-items: center
	padding-top: 3.5rem

.BannerTitle,
.BannerSubtitle
	text-align: center

.BannerTitle
	+common.sectionTitle
	margin-top: 1.75rem

.BannerSubtitle
	margin: 1rem 0 0

.BannerButtons
	display: flex
	flex-wrap: wrap
	margin-top: 2rem
	margin-bottom: 5rem
	width: 17.5rem

.BannerButton
	padding: .5rem
	width: 50%

.Wrapper
	background-color: #131313
	color: #fff
	padding: 3.125rem 0 5rem 0
	margin: 0.5rem
	margin: 5px 5px 0
	border-radius: 8px
	@media (min-width: common.$break48)
		margin: 0.5rem

.Section
	display: flex
	justify-content: space-between
	@media not all and (min-width: common.$break48)
		flex-direction: column

.Email
	+common.subtitle
	margin: 0

.FormBtn
	margin-top: 8px

.FormBtn,
.Links
	@media not all and (min-width: common.$break48)
		margin-bottom: 2.5rem

.Links
	display: flex
	flex-direction: column

.InstagramLink
	display: flex
	width: fit-content
	font-size: 1.5rem
	margin-top: 1rem
	&:hover
		color: #DD2A7B

.InstagramLabel
	font-size: 1rem
	line-height: 1.375rem
	margin-left: 0.5rem

.Link,
.Address,
.IcoDic
	line-height: 22px

.Link
	&:hover
		color: common.$red

.IcoDic
	margin: 0

.Line
	width: 100%
	border-top: 2px solid
	opacity: 0.1
	margin: 2rem 0
	@media (min-width: common.$break48)
		margin: 7.5rem 0 2rem

.CopyrightWrapper
	display: flex
	align-items: center
	color: #fff
	margin-bottom: 1rem
	@media (min-width: common.$break48)
		padding: 0 0.5rem

.CopyrightLogo
	font-size: 2.125rem
	line-height: 0
	margin-right: 1rem

.Copyright
	a
		text-decoration: underline
		&:hover
			text-decoration: none

.Copyright,
.TermsAndConditions
	+common.smallText14

.TermsAndConditions
	text-decoration: underline
	&:hover
		color: common.$red
	@media not all and (min-width: common.$break48)
		margin-bottom: 2.5rem

.Note
	+common.smallText12
	max-width: 23.75rem
	&:nth-last-child(1)
		@media not all and (min-width: common.$break48)
			margin-top: 1.25rem
	@media (min-width: common.$break48)
		padding: 0 0.5rem
