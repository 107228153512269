@use '../variables'

=text17()
	margin: 0
	font-weight: 500
	font-size: 17px
	line-height: 23px
	@media (min-width: variables.$break48)
		font-size: 20px
		line-height: 26px
