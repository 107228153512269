@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	padding: 1.5rem 1rem
	overflow: hidden
	@media (min-width: common.$break48)
		padding: 2.5rem

.isRed
	background-color: common.$red
	color: #fff
	border: none

	--parameter-CarouselNavigation-arrowHover-color: #000
	--parameter-CarouselNavigation-arrowHover-background: #EAEAEA

.isLight
	background-color: #fff
	color: #000

.isLokalYellow
	background-color: #F5EED4
	color: #000

.TitleWrapper
	padding-bottom: 1.25rem

.Title
	+common.sectionTitle

.Text
	margin-top: 8px
	&:empty
		display: none

.Carousel
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	margin-bottom: 2.5rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin: 0 -2.5rem
	padding: 1.25rem 1.75rem
	@media (min-width: common.$break48)
		padding: 1.25rem

.CarouselLinkItem
	&:hover
		color: common.$red

.CarouselImage
	position: relative
	border-radius: 1rem
	overflow: hidden
	line-height: 0
	aspect-ratio: 3/2

.SquareImages
	aspect-ratio: 1/1

.CarouselTitle
	+common.text17
	margin: 1rem 0 0.5rem

.CarouselText,
.CarouselName
	+common.smallText14

.CarouselText
	margin-top: 0.5rem
	@media (min-width: common.$break48)
		font-size: 16px
		line-height: 22px

.CarouselViewsWrapper
	margin-top: 0.5rem
	display: flex
	align-items: center

.CarouselViewsIcon
	display: flex
	margin-right: 0.5rem

.CarouselName
	font-weight: 500
