@use 'common'
@use 'sass:math'

.Wrapper
	position: absolute
	top: 0
	left: 0
	background-color: #fff
	color: #000
	z-index: 2
	width: 100%
	display: flex
	justify-content: flex-end
	@media not all and (min-width: common.$break48)
		padding: 1rem

.MinimalizeWrapper
	display: flex
	align-items: center
	justify-content: space-between
	z-index: 2
	padding: 0
	@media (min-width: common.$break48)
		position: absolute
		top: 1.75rem
		right: 1.75rem
		left: 1.75rem

.MinimalizeBtn
	display: flex
	align-items: center
	border: none
	background-color: transparent
	color: common.$red
	font-family: inherit
	padding: 0

.MinimalizeLabel
	+common.smallText14
	font-weight: 500
	padding-right: .625rem

.Container
	display: flex
	@media not all and (min-width: common.$break48)
		flex-direction: column

.LeftContent
	width: 100%
	@media (min-width: common.$break48)
		width: 40%

.ImageTextList
	position: sticky
	top: -1.25rem

.Carousel
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	// margin-bottom: 2.5rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	position: sticky
	top: -1.25rem

.CarouselItem
	width: 100%
	flex-shrink: 0
	scroll-snap-align: center
	position: relative

.CarouselImage
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0
	right: 0
	bottom: 0

.CarouselTextWrapper
	min-height: 75vh
	position: relative
	display: flex
	flex-direction: column
	justify-content: flex-end
	background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)
	padding: 1rem
	@media (min-width: common.$break48)
		min-height: 100vh

.CarouselText
	+common.sectionTitle
	display: flex
	align-items: flex-end
	color: #fff
	margin-bottom: 2rem

.FormWrapper
	display: flex
	justify-content: center
	width: 100%
	padding: 0 0 1rem
	@media (min-width: common.$break48)
		padding: 5rem 0 2rem
		width: 60%

.FormWrapperIn
	width: 27.5rem

.FormDesktopTitleWrapper
	display: none

	@media (min-width: common.$break48)
		display: block

.FormMobileTitleWrapper
	padding: 1.5rem 0

	@media (min-width: common.$break48)
		display: none

.Title
	+common.sectionTitle
	margin: 0 0 0.5rem
