@use 'common'
@use 'sass:math'

.Wrapper
	//

.Header
	position: relative
	min-height: 25vh
	margin: 0 -0.5rem
	overflow: hidden
	border-radius: 1rem
	@media (min-width: 40rem)
		margin: 0 -1.5rem 3rem

.Image,
.MobileImage
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0
	right: 0
	bottom: 0
	border-radius: 16px
	overflow: hidden

.Image
	display: none
	@media (min-width: common.$break48)
		display: block
		margin-top: 6px
	img
		border-radius: 1rem
		overflow: hidden

.MobileImage
	margin-top: 6px
	@media (min-width: common.$break48)
		display: none

.HeaderContent
	position: relative
	color: inherit
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: 25vh
	padding-top: 3rem
	margin: 6px -0.5rem 0 -0.5rem

	@media (min-width: common.$break48)
		padding-top: common.$header-height

.isWithImage
	min-height: 75vh
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 1) 100%)
	color: #fff
	border-radius: 30px

.BackButton
	border-radius: 1.5rem
	padding: 0.25rem 0.75rem 0.25rem 0.5rem
	margin: 2rem 0 6.25rem
	font-size: 12px
	line-height: 18px

.ButtonArrow
	font-size: 28px
	transform: rotate(180deg)

.HeaderContentIn
	display: flex
	flex-direction: column
	align-items: flex-end
	justify-content: space-between
	margin-bottom: 1rem
	@media (min-width: 40rem)
		margin-bottom: 2rem
	@media (min-width: common.$break48)
		margin-bottom: 2.1875rem
		flex-direction: row

.LogoMobile
	display: flex
	width: 8.75rem
	margin-right: auto
	margin-top: 3.75rem
	@media (min-width: common.$break48)
		display: none

.NameWrapper
	margin-bottom: 1.5rem
	@media (min-width: common.$break48)
		margin-bottom: 0
		padding-right: 1rem

.LogoDesktop
	display: none
	@media (min-width: common.$break48)
		display: flex
		max-width: 220px

.Name
	color: inherit
	margin-bottom: 1rem
	max-width: 47rem

.Text
	max-width: 48rem
	@media not all and (min-width: common.$break48)
		margin-bottom: 1.5rem

.genericPageText
	+common.text17
	max-width: 660px
	@media (min-width: common.$break62)
		margin-bottom: 4rem

.businessPageText
	max-width: 44rem

.jobPageText
	max-width: 47rem
	font-size: 16px
	line-height: 22px
	@media (min-width: common.$break48)
		font-size: 18px
		line-height: 24px

.Subtitle
	+common.subtitle
	margin: 0 0 1rem

.KeyInfoBox
	padding: 15px 10px
	color: #000
	background-color: #FCFCFC
	border: 2px solid rgba(0, 0, 0, 0.05)
	border-radius: 16px
	height: fit-content
	@media not all and  (min-width: common.$break48)
		width: 100%

.KeyInfoWrapper
	display: flex
	padding: 9px

.KeyIcon
	margin-right: 11px
	display: flex
	align-items: flex-start
	min-width: 1rem
	padding-top: 3px

.KeyInfo
	margin: 0
