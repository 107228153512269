@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	justify-content: space-between
	align-items: center
	border-radius: 1rem
	background-color: var(--SiteSwitcher-backgroundColor)
	color: var(--SiteSwitcher-textColor)
	padding: 1.25rem 1rem
	margin: 2.5rem 0
	@media (min-width: common.$break48)
		padding: 2rem 3rem

.Title
	+common.text17

.Arrow
	display: flex
	margin-left: 0.5rem
	font-size: 1.25rem
