@use 'common'

.Wrapper
	+common.smallText14
	display: inline-flex
	text-align: center
	justify-content: center
	align-items: center
	font-weight: 500
	cursor: pointer
	border: 2px solid
	border-radius: 4px
	text-decoration: none
	padding: 10px 14px
	transition: 0.2s ease-in-out all
	background-color: var(--Button-backgroundColor)
	color: var(--Button-textColor)
	border-color: var(--Button-borderColor)

	&:hover
		background-color: var(--Button-backgroundHoverColor)
		color: var(--Button-textHoverColor)
		border-color: var(--Button-borderHoverColor)

	&.is_fullWidth
		width: 100%

	&.is_withIcon
		display: flex
		justify-content: space-between

	&.is_variant_red
		color: #fff
		background-color: common.$red
		border-color: rgba(#000, 20%)
		&:hover
			background-color: #9F000F

	&.is_variant_white
		color: common.$red
		background-color: #fff
		border-color: common.$outline
		&:hover
			border-color: common.$red

	&.is_variant_yellow
		color: #000
		background-color: #F5EED4
		border-color: common.$outline
		&:hover
			background-color: pink
