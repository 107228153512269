@use '../variables'

=sectionTitle()
	font-weight: 500
	font-size: 26px
	line-height: 32px
	margin: 0
	@media (min-width: variables.$break48)
		font-size: 36px
		line-height: 42px
