@use 'common'
@use 'sass:math'

.Box
	background: rgba(255, 255, 255, 0.20)
	padding: 1.25rem 1rem
	border-radius: .5rem
	width: 100%
	@media (min-width: common.$break48)
		width: fit-content
		margin-left: 1rem

.view_size_small
	width: 3rem

.view_size_medium
	width: 4rem

.view_size_big
	width: 6rem

.Columns
	display: flex
	flex-wrap: wrap
	@media (min-width: common.$break30)
		flex-direction: row

.OpeningHours,
.SectionLabel
	+common.smallText14

.OpeningHours
	margin-right: 2.8125rem

.OpeningHours,
.AddressWrapper
	margin-top: 1.25rem

.Address
	text-decoration: underline
	&:hover
		text-decoration: none

.AddressNoUnderline
	text-decoration: none

.SectionLabel
	font-weight: 500

.Socials
	display: flex
	margin-top: 1.25rem

.Social
	color: #000000
	background-color: #ffffff
	font-size: 1.125rem
	display: flex
	padding: .3125rem
	border-radius: 50%
	margin-right: .75rem
