@use 'common'
@use 'sass:math'

.GalleryDesktop,
.GalleryMobile
	position: relative
	flex-wrap: wrap
	margin-top: 3.5rem

.GalleryDesktop
	display: none
	@media (min-width: common.$break48)
		display: flex

.GalleryMobile
	display: flex
	@media (min-width: common.$break48)
		display: none

.Image
	width: math.div(100%, 3)
	display: flex
	justify-content: center

	@media (min-width: common.$break30)
		width: math.div(100%, 4)
		&:nth-child(even)
			margin-top: 3rem
	@media (min-width: common.$break62)
		width: math.div(100%, 6)
	@media (min-width: common.$break75)
		width: math.div(100%, 8)

	@media not all and (min-width: common.$break30)
		&:nth-child(3n + 2)
			margin-top: 2rem

.ImageIn
	border-radius: 2rem
	overflow: hidden
	line-height: 0
	display: flex
	min-width: 80px
	width: 80px
	height: 80px
	@media (min-width: common.$break375)
		min-width: 100px
		width: 100px
		height: 100px
	@media (min-width: common.$break48)
		border-radius: 3rem
		min-width: 125px
		width: 125px
		height: 125px
	@media (min-width: 93.75rem)
		min-width: 150px
		width: 150px
		height: 150px
