@use 'common'
@use 'sass:math'

.Wrapper
	position: relative
	overflow: hidden

.Wrapper,
.Video
	display: flex
	width: 100%

.Video
	object-fit: cover
	cursor: pointer

.Video,
.Poster
	aspect-ratio: 16/9

.Poster
	background-color: #000000

.PosterButton
	position: absolute
	inset: 0
	background-color: transparent
	border: none
	margin: 0

.Play
	position: absolute
	inset: 0
	display: flex
	justify-content: center
	align-items: center
	font-size: 3.125rem

.Poster
	position: absolute
	inset: 0
	overflow: hidden
	display: flex
