@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	padding: 1.5rem 1rem
	overflow: hidden
	@media (min-width: common.$break48)
		padding: 2.5rem

// .WrapperIn
// 	max-width: 59rem

.TitleWrapper
	padding-bottom: 2.5rem

.Title
	+common.sectionTitle

.Text
	margin-top: 8px
	&:empty
		display: none

.Grid
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin-right: -1rem
	padding-bottom: 1rem
	gap: 1rem 0.2rem
	@media (min-width: common.$break62)
		display: grid
		grid-auto-flow: column
		grid-template-rows: auto auto
		grid-auto-rows: 1fr
		grid-template-columns: repeat(var(--Grid-columns), 1fr)

.GridItem
	display: flex
	flex-direction: column
	justify-content: space-between
	aspect-ratio: 0.8 / 1
	// background: #555555
	background-color: var(--theme-box-color, #{common.$darkGrey})
	border-radius: 8px
	// color: #fff
	margin: 8px 16px 8px 0
	padding: 1rem
	flex-shrink: 0
	scroll-snap-align: start
	width: 70%
	@media (min-width: common.$break30)
		width: 40%
	@media (min-width: common.$break62)
		aspect-ratio: unset
		height: 100%
		width: auto

.GridTitle,
.GridText
	margin: 0

.GridTitle
	font-weight: 400
	font-size: 18px
	line-height: 27px
	@media (min-width: common.$break30)
		font-size: 20px
	@media (min-width: common.$break62)
		font-size: 23px
		line-height: 32px
	@media (min-width: common.$break75)
		font-size: 26px
		line-height: 32px

.GridText
	+common.text
	a
		text-decoration: underline
		&:hover
			text-decoration: none
