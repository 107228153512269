@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	background-color: #fff
	color: #000
	padding: 1.5rem 1rem
	@media (min-width: common.$break48)
		padding: 2.5rem


.TitleWrapper
	border-bottom: 1px solid common.$outline
	padding-bottom: 1rem

.Title
	+common.sectionTitle
	text-align: start
	padding-right: 0.5rem

.Text
	margin-top: 8px
	&:empty
		display: none

.CollapsibleTitleWrapper
	display: flex
	justify-content: space-between
	align-items: center
	cursor: pointer
	border: none
	width: 100%
	background-color: transparent
	border-bottom: 1px solid common.$outline
	padding: 24px 0
	font-family: inherit

.CollapsibleTitle
	+common.text17
	color: #000
	text-align: left

.CollapsibleText
	+common.box
	border: none
	padding: 1rem
	background-color: common.$darkGrey

.Plus,
.Minus
	font-size: 1.5rem
	line-height: 0

.isOpen
	border-bottom: none
	.CollapsibleTitle
		color: common.$red
