@use 'common'
@use 'sass:math'

$gap: 1.875rem

.Wrapper
	+common.box
	overflow: hidden
	position: relative

.isRed
	background-color: common.$red
	color: #fff
	border-color: rgba(#000, 20%)

.isLightGrey
	background-color: #F3F3F3
	color: #000

.Carousel
	+common.hideHorizontalScrollBar
	display: flex
	margin-bottom: 2.5rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin: 0 -2.5rem
	padding: 1.25rem

.CarouselItem
	width: 100%
	flex-shrink: 0
	scroll-snap-align: center
	padding: 0 1.25rem
	margin-bottom: auto

.SideBySideContent
	display: flex
	flex-direction: column-reverse
	@media (min-width: common.$break48)
		gap: $gap
		flex-direction: row
		align-items: center
		justify-content: space-between

	&.isSize_small
		@media (min-width: common.$break48)
			padding: 1rem 0
	&.isSize_medium,
	&.isSize_big
		@media (min-width: common.$break48)
			padding-top: 1rem
	&.isSize_big
		align-items: flex-start

	&.isText_right
		@media (min-width: common.$break48)
			flex-direction: row-reverse

	&.noTextAndBig
		align-items: center
		padding-top: 5rem
		padding-bottom: 5rem

.Box
	display: flex
	flex-direction: column
	align-items: flex-start
	z-index: 0
	gap: $gap

	@media (min-width: common.$break48)
		width: 50%

	&In
		width: 100%
		display: flex
		justify-content: center
		align-items: center

		&.is_image
			// $aspectRatio: calc(var(--SideBySideContent-image-width) / var(--SideBySideContent-image-height))
			.Image
				// aspect-ratio: $aspectRatio
				// width: calc(100% * $aspectRatio)
				position: relative
				overflow: hidden
				border-radius: .625rem
				&In
					width: 70%
					height: 70%
					overflow: hidden
					img
						border-radius: .625rem
						overflow: hidden

				@support not (aspect-ratio: 1)
					&::before
						content: ''
						display: block
						width: 70%
						// padding-top: calc(70% / #{$aspectRatio})

		&.is_video
			.video
				width: 100%
				border-radius: .625rem

.BoxImage
	&.isSize_small
		width: 70%
		@media (min-width: common.$break48)
			width: 25%
	&.isSize_medium
		width: 50%
	&.isSize_big
		width: 100%
		.BoxIn
			padding: 0

.Content
	max-width: 25.625rem
	max-width: 46.75rem
	margin-bottom: 0
	padding-bottom: 3.5rem

.Title
	+common.sectionTitle
	@media not all and (min-width: common.$break48)
		padding-top: 1rem

.Text
	padding: 0.5rem 0 0
	@media (min-width: common.$break48)
		padding: 1.5rem 0 0

.Link
	padding-top: 1.5rem

.Navigation
	padding-bottom: 1.25rem
	position: absolute
	bottom: 0

	&.isText_right
		right: 1.25rem
