@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	padding: 1.5rem 1rem
	overflow: hidden
	@media (min-width: common.$break48)
		padding: 2.5rem

.WrapperIn
	max-width: 59rem

.TitleWrapper
	padding-bottom: 2.5rem

.Title
	+common.sectionTitle

.Text
	margin-top: 8px
	&:empty
		display: none

.Gallery
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	margin-bottom: 2.5rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin: 0 -2.5rem
	padding: 1.25rem 1.75rem

	@media (min-width: common.$break48)
		padding: 1.25rem
		display: grid
		grid-template-columns: 437fr 228fr 94fr 229fr 100fr 128fr 60fr
		grid-template-rows: 291fr 136fr 110fr 57fr 20fr 29fr 109fr 101fr
		gap: .8125rem
		aspect-ratio: 1438 / 1035
	@media (min-width: common.$break62)
		gap: 1.625rem

.Image
	position: relative

	@media not all and (min-width: common.$break48)
		width: 80%
		flex-shrink: 0
		scroll-snap-align: center
		padding: 0 0.75rem

	&:nth-child(1)
		grid-column: 1
		grid-row: 1
		// aspect-ratio: math.div(437, 291)
	&:nth-child(2)
		grid-column: 2 / 5
		grid-row: 1 / 3
		// aspect-ratio: math.div(603, 453)
	&:nth-child(3)
		grid-column: 5 / 7
		grid-row: 1 / 3
		// aspect-ratio: math.div(264, 453)
	&:nth-child(4)
		grid-column: 1
		grid-row: 2 / 4
	&:nth-child(5)
		grid-column: 2 / 4
		grid-row: 3 / 5
	&:nth-child(6)
		grid-column: 4 / 6
		grid-row: 3 / 6
	&:nth-child(7)
		grid-column: 6 / 8
		grid-row: 3 / 7
	&:nth-child(8)
		grid-column: 1
		grid-row: 4 / 8
	&:nth-child(9)
		grid-column: 2 / 3
		grid-row: 5 / 8
	&:nth-child(10)
		grid-column: 3 / 6
		grid-row: 6 / 9

.ImageIn
	border-radius: 1rem
	overflow: hidden
	line-height: 0
	display: flex
	height: 100%
