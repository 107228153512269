@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column
	align-items: center
	background-color: #fff
	color: #000
	border-radius: 1rem
	padding: 1.5625rem
	position: absolute
	top: 1rem
	max-width: 48rem
	position: relative

	@media (min-width: common.$break30)
		padding: 1.5625rem 2.5rem
	@media (min-width: common.$break48)
		padding: 5rem 5rem 3.125rem

.Title
	+common.subtitle
	margin: 1.25rem 0 0
	text-align: center
	@media (min-width: common.$break48)
		margin: 2.5rem 0 0

.Text
	+common.text
	margin-bottom: 2.5rem
	text-align: center

.Checkmark
	display: flex
	font-size: 1.75rem
