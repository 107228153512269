@use 'common'
@use 'sass:math'

.InputName
	display: flex
	border-bottom: 1px solid common.$outline
	margin-top: 1.25rem
	&Gdpr
		border-bottom: 0

.InputLabel
	white-space: nowrap
	padding-right: 1rem
	margin-block: 0.8rem
	a
		text-decoration: underline
		text-underline-offset: 2px
		&:hover
			text-decoration: none

	&Gdpr
		margin-block: 0
		font-size: 14px

.Input
	+common.text
	border: none
	width: 100%
	background-color: transparent
	color: #000
	&:focus
		outline: none
		border-bottom: 1px solid common.$red
		margin-bottom: -1px
		border-radius: 0
		opacity: 0.75

.InputFileWrapper
	position: relative
	border-radius: 0
	// height: 12.5rem
	cursor: pointer
	border: 2px dashed common.$red
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	border-radius: 1rem
	width: 100%
	margin: 2rem 0 0.5rem

.Uploaded
	border-color: common.$green
	flex-direction: revert
	justify-content: flex-start
	padding: 1rem
	cursor: auto
	.FileImage
		width: 75px
		margin-top: 0

.FileRemoveBtn
	color: common.$red
	background-color: transparent
	border: none
	margin-left: auto
	text-decoration: underline
	&:hover
		text-decoration: none

.FileImage
	display: flex
	margin-top: 0.8rem
	width: 80px
	height: auto

.FileTitle,
.FileTypeLabel
	margin: 0

.FileTitle
	+common.smallText12

.FileTypeLabel
	font-size: 10px
	line-height: 14px
	color: #000
	opacity: 0.5

.InputFile
	visibility: hidden

.TextAreaInput
	+common.smallText14
	background-color: transparent
	border: 1px solid common.$outline
	border-radius: 0.5rem
	width: 100%
	margin: 0.5rem 0 0
	padding: 1rem
	resize: none

.Submit
	margin-top: 2rem

.Checkmark
	display: flex
	font-size: 1.75rem

.CheckBoxInput
	display: none

.Check
	cursor: pointer
	display: inline-block
	width: 1.25rem
	height: 1.25rem
	background: #fff
	border: 1px solid #D2D1D0
	border-radius: 3px
	min-width: 1.25rem

.CheckBoxInput:checked + .Check
	background: common.$red
	border: none

.CheckmarkIcon
	color: #fff
	display: flex
	align-items: center
	justify-content: center

.CheckmarkGdpr
	color: #fff
	display: flex
	align-items: center
	justify-content: center
	margin-top: 2px
	svg
		color: white
