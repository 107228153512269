@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-arrow
		+normalizeSize(24, 24)
	&-arrowDown
		+normalizeSize(22, 13)
	&-checkmark
		+normalizeSize(29, 28)
	&-close
		+normalizeSize(40, 40)
	&-hamburger
		+normalizeSize(40, 40)
	&-facebook,
	&-globe,
	&-instagram
		+normalizeSize(18, 19)
	&-logo
		+normalizeSize(129, 34)
	&-logoHover
		+normalizeSize(41, 41)
	&-linkedin
		+normalizeSize(38, 37)
	&-minus
		+normalizeSize(24, 24)
	&-play
		+normalizeSize(86, 110)
	&-plus
		+normalizeSize(24, 24)
	&-read
		+normalizeSize(22, 14)
	&-twitter
		+normalizeSize(40, 35)
