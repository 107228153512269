@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	background-color: #fff
	padding: 24px 0
	color: #000
	@media (min-width: common.$break48)
		padding: 34px 0

.HeadingWrapper
	display: flex
	justify-content: space-between
	margin-bottom: 2rem
	align-items: flex-start
	flex-direction: column-reverse
	@media (min-width: common.$break48)
		align-items: center
		flex-direction: row

.HeadingText
	@media (min-width: common.$break48)
		max-width: calc((100% / 3) * 2 + 3rem)

.Logo
	width: 6rem
	min-width: 6rem
	max-width: 6rem

.Banners
	display: grid
	gap: 1.5rem
	@media (min-width: common.$break48)
		grid-template-columns: repeat(3, 1fr)

.Banner
	+common.box
	color: inherit
	background-color: common.$darkGrey
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: 1.5rem
	&:hover
		background-color: common.$red
		color: #fff
		border-color: rgba(#000, 20%)
		.Plus

			background-color: #fff

	&:nth-child(4n + 1),
	&:nth-child(4n + 2)
		grid-row: span 2

	&:nth-child(4n + 3),
	&:nth-child(4n + 4)
		.TitleWrapper
			display: flex
			align-items: flex-start

		.BannerTitle
			@media (min-width: common.$break48)
				width: 60%
		.DesktopImage
			width: 40%
	@media (min-width: common.$break48)
		padding: 1rem
	@media (min-width: common.$break62)
		padding: 2rem

.BannerTitle,
.BannerText
	text-align: start

.BannerTitle
	+common.subtitle
	margin-top: 0
	margin-bottom: 0.5rem

.BannerText
	+common.smallText14

.Plus
	display: flex
	font-size: 1.5rem
	border-radius: 50%
	margin-top: .5rem
	width: fit-content

.MobileImage
	@media (min-width: common.$break48)
		display: none

.DesktopImage
	display: none
	@media (min-width: common.$break48)
		display: flex
