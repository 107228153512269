@use 'common'
@use 'sass:math'

.Wrapper
	background-color: #FCFCFC
	color: #000
	display: flex
	flex-direction: column
	align-items: center
	border-radius: 1rem
	border: 2px solid common.$darkGrey

.noTitle
	@media (min-width: common.$break48)
		padding-top: 1rem

.noButton
	@media (min-width: common.$break48)
		padding-bottom: 1rem

.Title
	+common.subtitle
	font-weight: 500
	padding: 0 1rem
	margin: 0
	text-align: center
	padding-top: 2.5rem
	@media (min-width: common.$break48)
		padding-top: 5rem

.List
	display: flex
	padding: 3.75rem

	@media not all and  (min-width: common.$break48)
		flex-direction: column
		padding: 1.25rem

.Item
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 1.25rem
	@media (min-width: common.$break48)
		border-left: 2px solid #9B9996
		padding: 0 4rem
	&:nth-child(1)
		border-left: none

.ItemTitle
	font-size: 48px
	line-height: 54px
	font-weight: 500
	margin: 0
	text-align: center
	@media (min-width: common.$break48)
		font-size: 64px
		line-height: 64px

.isRed
	color: common.$red
.ItemText
	margin: 0

.Button
	padding-bottom: 2.5rem
	@media (min-width: common.$break48)
		padding-bottom: 5rem
