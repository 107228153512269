@use 'common'
@use 'sass:math'

@keyframes buttonAnimation
	0%
		transform: scaleX(0)

	100%
		transform: scaleX(1)

.Wrapper
	position: absolute
	top: 0
	left: 0
	background-color: #000
	color: #fff
	z-index: 2
	width: 100%
	display: flex
	align-items: center
	padding: 1rem
	@media (min-width: common.$break62)
		padding: 0
		height: 100%

.CloseBtn
	display: flex
	margin-left: auto
	border: none
	background-color: transparent
	font-size: 2.5rem
	color: #fff
	position: absolute
	right: 0
	top: 7px
	z-index: 3
	background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
	padding: 0
	@media  (min-width: common.$break62)
		top: 18px

.ContentWrapper
	display: flex
	flex-direction: column
	@media  (min-width: common.$break62)
		flex-direction: row

.LightBoxMediaWrapper
	display: flex
	flex-wrap: wrap
	width: 100%
	height: calc( 100vh - 3.5rem)
	cursor: url("/images/cursorLeft.svg") 60 60, auto
	@media  (min-width: common.$break62)
		width: math.div(100%, 3)

.isMoreMedia
	aspect-ratio: 1/1
	height: auto
	@media  (min-width: common.$break62)
		height: calc( 100vh - 3.5rem)
		height: 100vh
		width: 50%

.LightBoxMedia
	width: 100%
	position: relative
	height: calc( 100vh - 3.5rem)

.isSmallerImage
	width: calc(50% - 2rem)
	height: auto
	margin: 1rem

.Content
	display: flex
	flex-direction: column
	justify-content: flex-end
	width: 100%

	@media  (min-width: common.$break62)
		cursor: url("/images/cursor.svg") 60 60, auto
		padding-left: 25px
		width: calc(100% / 3 ) * 2

.Gradient
	background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, #000000 100%)
	width: 100%
	height: 2rem
	position: absolute
	z-index: 2
	pointer-events: none
	left: 0
	right: 0

.SectionMobileTitles
	+common.hideHorizontalScrollBar
	display: flex
	flex-wrap: wrap
	margin: 0 0 0.5rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin-left: -1rem
	padding-left: 1rem
	&::after
		content: ""
		width: 50px
		flex-shrink: 0
	@media  (min-width: common.$break62)
		display: none

.SectionDesktopTitles
	display: none
	@media  (min-width: common.$break62)
		display: flex
		flex-wrap: wrap
		margin-top: 1.75rem
		position: absolute
		top: 0

.SectionTitle
	position: relative
	padding: 0.25rem 0.75rem
	color: #fff
	border: none
	background-color: #343434
	border-radius: 1.25rem
	margin-right: 0.5rem
	margin-bottom: 0.5rem
	width: fit-content
	z-index: 1
	overflow: hidden
	flex-shrink: 0
	// scroll-snap-align: center
	&:hover
		color: red

.titleActive
	color: common.$red

	&::before
		animation: buttonAnimation 25s linear
		content: ""
		position: absolute
		inset: 0
		background-color: #fff
		height: 100%
		width: 100%
		z-index: -1
		overflow: hidden
		transform-origin: left top
		@media (min-width: common.$break48)
			animation: buttonAnimation 25s linear

	&.hasVideo
		&::before
			animation: buttonAnimation var(--video-duration, 300s) linear

.ArrowMobileBtn
	display: flex
	justify-content: center
	align-items: center
	border: none
	background-color: #fff
	border-radius: 50%
	margin-left: auto
	width: 3.75rem
	font-size: 2.25rem
	margin: 40px 0 80px
	color: #000
	aspect-ratio: 1
	svg
		flex-shrink: 0
	&:hover
		color: #fff
		background-color: common.$red
		border: none

	@media (min-width: common.$break62)
		display: none

.ArrowMobileBtns
	display: flex
	justify-content: space-between
	width: 100%

.Previous
	transform: rotate(-180deg)
.Video,
.Image
	width: 100%

.Video
	height: 50vh
	object-fit: cover
	@media (min-width: common.$break62)
		height: 100vh

.LightBoxTitle
	+common.subtitle
	margin: 1rem 0 .5rem

.LightBoxText
	margin-bottom: 0
	max-width: 40.625rem

.LightBoxLink
	margin: 1rem 0 0

.isMoreMediaLink
	@media (min-width: common.$break62)
		margin-bottom: 1rem
