@use 'common'
@use 'sass:math'

.FilterSectionWrapper
	display: flex
	flex-direction: column
	z-index: 1

.CollapsibleTitleWrapper
	display: flex
	align-items: center
	border: none
	background-color: transparent
	font-family: inherit

.CollapsibleTitle
	+common.smallText14
	padding-right: .375rem
	color: #000

.isOpen
	color: common.$red

.Arrow
	font-size: 0.5rem
	display: flex

.Close
	transform: rotateX(-180deg)translateY(0)
	transform: rotateX(-180deg)

.Pointer
	@media (min-width: common.$break48)
		border: 2px solid common.$outline
		width: 1.25rem
		height: 1.25rem
		position: absolute
		left: 3rem
		top: -0.5rem
		transform: rotate(45deg)
		background-color: #fff

.PointerShade
	@media (min-width: common.$break48)
		position: absolute
		background-color: #fff
		width: 95%
		height: 1rem
		top: 0
		left: 0
		border-radius: 10px

.FilterSection
	border: 2px solid common.$outline
	width: 100%
	border-radius: 0.5rem
	margin-top: 1.25rem
	position: absolute
	left: 0
	right: 0
	background-color: #fff
	padding: 0.25rem 0

	@media (min-width: common.$break48)
		width: 16.25rem
		left: auto
		right: auto
		margin-left: -1rem

.FiltersWrapper
	max-height: 18.75rem
	overflow: scroll
	padding: 0 1rem

.InputWrapper
	display: flex
	justify-content: space-between
	margin: .875rem 0
	cursor: pointer

.InputLabel
	margin: 0

.Input
	visibility: hidden

.Check
	display: inline-block
	width: 1.25rem
	height: 1.25rem
	background: #fff
	border: 1px solid #D2D1D0
	border-radius: 3px
	min-width: 1.25rem
	.CheckmarkIcon
		display: none

.Input[type="checkbox"]:checked + .Check
	background: common.$red
	border: none

	.CheckmarkIcon
		color: #fff
		font-size: 28px
		display: flex
		align-items: center
		width: 1.25rem
		height: 1.25rem
