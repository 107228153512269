@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column
	align-items: center
	background-color: #fff
	color: #000
	border-radius: 1rem
	padding: 1.5625rem
	position: absolute
	top: 1rem
	max-width: 48rem
	position: relative

	@media (min-width: common.$break30)
		padding: 1.5625rem 2.5rem
	@media (min-width: common.$break48)
		padding: 5rem 5rem 3.125rem

.Close
	border: none
	background-color: transparent
	position: absolute
	top: 20px
	right: 20px
	padding: 0
	font-size: 2.5rem
	line-height: 0
	color: common.$red

.Title
	+common.subtitle
	margin: 1.25rem 0 0
	text-align: center
	@media (min-width: common.$break48)
		margin: 2.5rem 0 0

.Text
	+common.text
	margin-bottom: 2.5rem
	text-align: center

.Checkmark
	display: flex
	font-size: 1.75rem

.SuccessOtherJobsTitle
	+common.text17
	max-width: 30rem
	font-weight: 400
	text-align: center

.OtherPagesTitleWrapper
	margin-top: 4.75rem
	width: 100%
	display: flex
	justify-content: space-between

.Line
	width: 6.25rem
	border-top: 1px solid common.$outline
	margin: 1rem 0 3.5rem

.SuccessOtherJobsButtonTitle
	border: 1px solid common.$red
	padding: 0.5rem
	border-radius: .25rem
	background-color: transparent
	color: common.$red

.Jobs
	display: flex
	flex-wrap: wrap
	margin-top: 2rem
	border: 2px solid common.$outline
	border-radius: 0.5rem
	margin-bottom: 6.25rem

.Job
	display: flex
	padding: 1.5rem 0
	border-top: 1px solid common.$outline
	width: 100%
	margin: 0 20px
	&:nth-child(1)
		border-top: 0

.JobImage
	display: flex
	min-width: 6rem
	width: 6rem
	height: 6rem
	border-radius: 0.5rem
	overflow: hidden
	@media (min-width: common.$break48)
		min-width: 8.75rem
		width: 8.75rem
		height: 8.75rem

.JobInfoBox
	display: flex
	flex-direction: column
	justify-content: space-between
	margin-left: 1rem
	width: 100%

.TitleWrapper
	display: flex
	justify-content: space-between

.JobTitle
	+common.text17
	max-width: 16.25rem

.InputWrapper
	display: flex
	height: fit-content
	justify-content: space-between
	margin: .875rem 0
	cursor: pointer

.InputLabel
	margin-left: .5rem

	.Input[type="checkbox"]:checked ~ &
		color: common.$red

.Input
	visibility: hidden

.Check
	display: inline-block
	width: 1.25rem
	height: 1.25rem
	background: #fff
	border: 1px solid #D2D1D0
	border-radius: 50%
	.CheckmarkIcon
		display: none

.Input[type="checkbox"]:checked + .Check
	background: common.$red
	border: none

	.CheckmarkIcon
		color: #fff
		font-size: 28px
		display: flex
		align-items: center
		width: 1.25rem
		height: 1.25rem

.JobText
	+common.smallText12
	max-width: 24.5rem

.JobLabels
	display: flex

.JobLabel
	border: 1px solid #000
	border-radius: .25rem
	padding: 6px 7px
	margin-right: .5rem
	display: flex
	align-items: center
	text-align: center

.BottomBar
	position: sticky
	bottom: 0
	display: flex
	justify-content: space-between
	align-items: center
	z-index: 2
	background-color: #fff
	width: 95vw
	box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25)
	border-radius: 8px
	padding: .5rem 0 .5rem .875rem

.SuccessOtherJobsSelectionTitle
	margin: 0

.Buttons
	display: flex

.Button
	margin-right: .5rem
