@use 'common'
@use 'sass:math'

.Wrapper
	+common.box
	background-color: #fff
	color: #000

.Title
	+common.sectionTitle
	margin: 1.5rem 0 1rem

.FilterSections
	display: flex
	position: relative

.FilterTags
	display: flex
	flex-wrap: wrap
	margin-top: 0.5rem

.FilterTag
	display: flex
	align-items: center
	background-color: common.$red
	padding: 0.375rem 0.5rem 0.375rem 0.75rem
	border-radius: 2.25rem
	margin: .5rem .5rem 0 0
	color: #fff

.FilterTagName
	+common.smallText12

.FilterTagRemove
	display: flex
	background-color: transparent
	border: none
	padding: 0
	font-size: 1.5rem
	color: #fff

.Jobs
	display: flex
	flex-wrap: wrap
	margin-left: -20px
	margin-top: 2rem

.Job
	display: flex
	padding: 2rem 0
	border-top: 1px solid common.$outline
	width: 100%
	margin: 0 16px
	@media (min-width: common.$break62)
		width: calc(50% - 40px)

.JobImage
	display: flex
	min-width: 6rem
	width: 6rem
	height: 6rem
	border-radius: 0.5rem
	overflow: hidden
	@media (min-width: common.$break48)
		min-width: 8.75rem
		width: 8.75rem
		height: 8.75rem

.JobInfoBox
	display: flex
	flex-direction: column
	justify-content: space-between
	margin-left: 1rem

.JobTitle
	+common.text17
	font-weight: 400
	max-width: 16.25rem

.JobText
	+common.smallText12
	max-width: 24.5rem
	margin: .25rem 0 .5rem

.JobLabels
	display: flex

.JobLabel
	font-size: 10px
	line-height: 14px
	border: 1px solid #000
	border-radius: .25rem
	padding: 6px 7px
	display: flex
	align-items: center
	text-align: center
	@media (min-width: common.$break48)
		+common.smallText12

.JobLabel + .JobLabel
	margin-left: .5rem

.Button
	margin-bottom: 2.5rem

.Line
	margin: 1.5rem 0
	border-top: 1px solid #9B9996
	@media (min-width: common.$break48)
		margin: 2.5rem 0

.BannerWrapper
	+common.box
	background-color: common.$darkGrey
	margin-bottom: 1.5rem
	@media (min-width: common.$break48)
		margin-bottom: 2.5rem

.Banner
	display: flex
	flex-direction: column
	align-items: center
	padding: 1rem 0
	@media (min-width: 40rem)
		padding: 2rem 0
	@media (min-width: common.$break48)
		flex-direction: row
		padding: 2rem 1.5rem

.Content,
.Image
	width: 100%
	@media  (min-width: common.$break48)
		width: 50%

.BannerTitle
	+common.sectionTitle

.BannerLink
	margin-top: 1rem

.NoFilter
	+common.box
	display: flex
	flex-direction: column
	align-items: center
	background-color: common.$red
	margin-top: 1.75rem
	padding: 3.5rem 0
	margin-bottom: 2.5rem

.NoFilterTitle
	+common.subtitle
	color: #fff
	max-width: 31.25rem
	text-align: center
