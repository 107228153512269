@use 'common'
@use 'sass:math'

.Wrapper
	//

.Header
	display: flex
	flex-direction: column
	align-items: center
	padding-top: 110px
	margin: .375rem auto 0
	overflow: hidden
	max-width: 124rem
	@media (min-width: common.$break48)
		padding: 110px 2rem 0
		padding-top: 0
		height: 80vh

.HeaderIn
	display: flex
	flex-direction: column-reverse
	position: relative
	@media (min-width: common.$break48)
		flex-direction: row
		height: 100%
		align-items: center
		width: 100%

.LeftColumn
	padding: 2rem 1rem 4rem
	@media (min-width: 40rem)
		padding: 2rem 2rem 4rem
	@media (min-width: common.$break48)
		padding: 0
		width: 40%
.GalleryDesktop
	@media (min-width: common.$break48)
		width: 60%

.Logo
	display: none
	@media (min-width: common.$break48)
		display: flex
		width: 3.75rem

.Name
	color: inherit
	font-size: 2.375rem
	line-height: 2.875rem
	max-width: 52rem
	margin: 24px 0
	@media (min-width: common.$break62)
		font-size: 4rem
		line-height: 4.75rem

.Text
	font-size: 1.5rem
	line-height: 1.875rem
	margin-bottom: 1.5rem

.GalleryDesktop
	display: flex
	position: relative
	flex-wrap: wrap
	height: 33vh
	overflow: hidden
	@media (min-width: common.$break48)
		height: auto
		overflow: visible
		height: 100%

.GalleryAnimation
	position: absolute
	top: 50%
	leftt: 50%

.IlustrationImageWrapper
	display: flex
	align-items: flex-end
	align-self: flex-end
	width: 100%
	justify-content: flex-end
	margin-left: auto
	position: relative
	aspect-ratio: 1
	@media (min-width: common.$break48)
		width: 50%

.IlustrationImage
	visibility: hidden
	@media (min-width: common.$break48)
		visibility: visible

$images-y-offset: -20vw

@keyframes animationProgress
	0%
		transform: translate(-50%, -50%) rotate(calc((360deg / var(--items-count) * var(--item-counter) - 90deg))) translateY($images-y-offset)
	100%
		transform: translate(-50%, -50%) rotate(calc((360deg / var(--items-count) * var(--item-counter)))) translateY($images-y-offset)

.Image
	border-radius: 6px
	overflow: hidden
	line-height: 0
	display: flex
	width: 20vw
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%) rotate(calc((360deg / var(--items-count) * var(--item-counter) - 90deg))) translateY($images-y-offset)
	transform-origin: center calc(0% + 65vw)
	animation: animationProgress 10s forwards
	animation-timing-function: ease-out

	@media (min-width: common.$break48)
		width: 8vw
		transform-origin: center calc(0% + 15vw)

.Overlay
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
	background: linear-gradient(180deg, rgba(62, 43, 20, 0) 0%, #3E2B14 100%)
	position: absolute
	height: 25%
	width: 100%
	bottom: 0
	pointer-events: none
	@media (min-width: common.$break48)
		width: 50vw
		right: 0
