@use 'common'

.Wrapper
	display: flex
	margin-left: 1rem

.Arrow
	display: flex
	align-items: center
	justify-content: center
	font-size: 1.5rem
	line-height: 0
	border: 1px solid common.$outline
	background-color: #fff
	border-radius: 50%
	width: 2.5rem
	height: 2.5rem
	visibility: hidden
	opacity: 0.5
	padding: 0
	color: #000
	pointer-events: none
	&:hover
		color: var(--parameter-CarouselNavigation-arrowHover-color, #fff)
		background-color: var(--parameter-CarouselNavigation-arrowHover-background, common.$red)
		border: none

.Previous
	transform: rotate(-180deg)
	margin-right: .5rem

.is_visible
	visibility: visible

.is_clickable
	visibility: visible
	pointer-events: auto
	opacity: 1
