@use 'common'
@use 'sass:math'

.Wrapper
	position: absolute
	z-index: 2
	width: 100%
	height: common.$header-height
	display: flex
	align-items: center

.Hamburger,
.Locales
	width: math.div(100%, 3)

.HeaderWrapper
	display: flex
	justify-content: space-between
	align-items: center
	padding-top: 21px

.Hamburger,
.Close
	display: flex
	align-items: center
	border: none
	background-color: transparent
	font-size: 2.5rem

.Hamburger
	color: var(--Hamburger-color)
	padding: 0
	&:hover
		.HamburgerLine
			&:nth-child(1)
				transform: translateY(-3px)
			&:nth-child(3)
				transform: translateY(3px)

.HamburgerIcon
	display: flex
	flex-direction: column
	margin-right: 1rem

.HamburgerLine
	width: 1.625rem
	height: 0.125rem
	margin: 0.1875rem 0
	background-color: currentColor
	border-radius: 0.1875rem
	transition: 0.2s ease transform

.LogoWrapperIn
	display: flex
	align-items: center
	justify-content: center
	position: relative
	&:hover
		.LogoFull
			opacity: 0
		.LogoSmall
			opacity: 1

.LogoFull,
.LogoSmall
	font-size: 30px
	line-height: 0
	position: absolute
	top: 0
	left: 50%
	transform: translate(-50%, -50%)
	transition-duration: 0.3s
	transition-property: opacity
	transition-timing-function: linear
	color: var(--Logo-color)

	@media (min-width: common.$break48)
		font-size: 34px

.LogoSmall
	opacity: 0
	color: common.$red

.MenuLabel
	+common.text
	@media not all and (min-width: common.$break48)
		display: none

.Locales
	display: flex
	justify-content: flex-end
	padding-right: 1.5rem

.LocaleWrapper
	display: flex
	align-items: center

.MissingLocale
	display: none

.Locale
	+common.text
	text-transform: uppercase

.isActiveLocale
	display: none

.Close
	margin: 21px 0 0 auto
	color: common.$red
	transition: 0.3s ease-in-out transform
	transform-origin: center center
	padding: 0

	&:hover
		transform: rotate(90deg)

.Menu
	width: 0
	display: none

.isMenuOpen
	display: block
	position: absolute
	inset: -8px 0 0 0
	width: 100%
	height: fit-content
	background-color: #fff
	z-index: 3
	padding-bottom: 2rem
	color: #000
	margin: 0 auto
	@media (min-width: common.$break48)
		border-radius: 1rem
		width: calc(100% - 1rem)
		inset: 0

.isMenuOpenShadow
	position: absolute
	inset: 0
	width: 100%
	height: 100vh
	background-color: rgba(#000, 0.5)
	position: fixed

.LogoMenu
	width: 1.9375rem
	position: absolute
	top: 0
	padding-top: 21px
	@media (min-width: common.$break48)
		width: 40px

.MenuTitle
	+common.subtitle
	color: common.$red

.MenuText
	+common.smallText14
	max-width: 31.25rem

.MenuSections
	display: flex
	@media not all and (min-width: common.$break48)
		flex-direction: column

.MenuSection
	+common.box
	background-color: common.$darkGrey
	border: 2px solid common.$outline
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: 1.5rem 1rem
	margin: 2rem 0 0 0
	@media (min-width: common.$break48)
		padding: 2rem

	&:nth-child(1)
		@media (min-width: common.$break48)
			width: 65%
	&:nth-last-child(1)
		@media (min-width: common.$break48)
			width: 35%
.MenuSection + .MenuSection
	@media (min-width: common.$break48)
		margin-left: 1.5rem

.Businesses
	display: flex
	flex-wrap: wrap

.Business,
.BusinessLokal
	display: flex
	align-items: center
	min-width: fit-content
	width: 100%
	padding: .3125rem
	&:hover
		color: common.$red
	@media (min-width: common.$break48)
		width: math.div(100%, 3)

.BusinessLokal
	@media (min-width: common.$break48)
		width: 50%

.BusinessLogo
	width: 45px
	height: auto
	margin-right: 10px
	@media (min-width: common.$break48)
		margin-right: 7px

.BusinessName
	+common.smallText14
	width: 10rem

.MenuSectionLink
	display: flex
	margin-top: 3.25rem
	width: fit-content
	color: common.$red
	text-decoration: underline
