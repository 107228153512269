@use 'common'
@use 'sass:math'

.Container
	position: fixed
	bottom: 1rem
	left: 0
	right: 0
	z-index: 2

.Wrapper
	display: flex
	align-items: center
	justify-content: space-between
	background-color: #fff
	padding: 0.5rem 0.5rem 0.5rem 1rem
	box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25)
	border-radius: 8px
	margin: 0 -0.5rem
	z-index: 1
	@media (min-width: 40rem)
		margin: 0 -1rem

.Title
	+common.smallText14
	color: #000
	padding-right: 1.5rem
	&:empty
		display: none

.Link
	+common.smallText14
	color: #fff
	background-color: common.$red
	border: 2px solid rgba(0, 0, 0, 0.2)
	padding: 10px 14px
	border-radius: 4px
	font-weight: 500

.FormButtonLabel
	margin: 0
	white-space: nowrap
