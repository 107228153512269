@use 'common'
@use 'sass:math'

.InputName
	display: flex
	flex-direction: column
	border-bottom: 1px solid common.$outline
	margin-top: 3.25rem
	@media (min-width: common.$break48)
		flex-direction: row

.InputLabel
	white-space: nowrap
	padding-right: 1rem

.Input
	+common.text
	border: none
	width: 100%
	background-color: transparent
	color: #000
	&:focus
		outline: none
		border-bottom: 1px solid common.$red
		margin-bottom: -1px
		border-radius: 0
		opacity: 0.75

.Submit
	margin-top: 3.5625rem

.Checkmark
	display: flex
	font-size: 1.75rem
